.homePage {
  .workBox {
    background: linear-gradient(180deg, #4fb7f2 0%, #3364f6 100%);
  }
  .talk-text {
    border-right: 2px solid #c9c9c9;
    border-left: 2px solid #c9c9c9;
  }
  .bg-upper {
    background-color: transparent;
    background-image: linear-gradient(182deg, #e1ebff 23%, #ffffff 100%);
    position: relative;
    z-index: -100;
  }
  .bg-overlay {
    background-image: url("../../../public/Background.png");
    background-position: 3px 0px;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -100;
  }
}
