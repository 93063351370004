.our-story{
    .example{background: #3364f6;







    }
}